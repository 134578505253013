import React from "react";
import NavBar from "../navbar/navbar.tsx";
import Footer from "../footer/footer.tsx";
import Seo from "../seo/seo.tsx";

const seo = {
    title: "Conectia - Políticas de privacidad",
    description: "Nuestra política de privacidad explica cómo recopilamos, usamos y protegemos la información personal de nuestros usuarios. Tu privacidad es importante para nosotros y estamos comprometidos a proteger tus datos."
}

function PrivacyPolicy() {
    return (
        <div className="conectia-dark bg-dark text-light">
            <Seo title={seo.title} description={seo.description} />
            <NavBar
                className="bg-dark">
            </NavBar>
            <article className="container-lg p-5">
                <h1>
                    POLÍTICA DE PRIVACIDAD Y AVISO LEGAL
                </h1>
                <p>
                    Información del titular de la web Conectia OÜ registry code: 16847069 en el Juzgado del Departamento de Registros del condado de Tartu,
                    Tallinn, Estonia en Octubre 2023, con número de teléfono +34 673482121 y dirección electrónica <a href="mailto:info@conectia.pro">info@conectia.pro</a>.
                    Responsable de protección de datos.
                </p>
                <p>
                    El titular es el responsable de los datos personales que son recabados por la navegación y uso de esta web conforme a los requisitos establecidos por el <span>REGLAMENTO (UE) 2016/679</span> relativo a la protección de datos de las personas físicas así como conforme a la <span>Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSI-CE)</span>.
                    Al utilizar este sitio web entendemos que ha leído y comprendido la información que se expone en relación con el tratamiento de sus datos de carácter personal.
                </p>
                <section>
                    <h2>
                        Política de protección de datos
                    </h2>
                    <p>
                        El responsable aplica el principio de responsabilidad activa en el tratamiento de los datos de carácter personal, manteniendo una constante puesta al día y una promoción de la mejora continua del sistema de protección de datos conforme a los requisitos legales exigibles, garantizando en todo caso:
                        <ul>
                            <li>
                                Que los datos son tratados de manera lícita, leal y transparente.
                            </li>
                            <li>
                                Que los datos tratados son exactos, adecuados, pertinentes y limitados en relación con los fines para los que son recogidos.
                            </li>
                            <li>
                                Que los fines para los que son recogidos son explícitos y legítimos y que no son tratados de manera incompatible con dichos fines.
                            </li>
                        </ul>
                        La finalidad de este documento es informar a los usuarios sobre qué hacemos con sus datos personales, cómo se recaban, para qué se utilizan, los derechos que les asisten así como toda la información
                        legal necesaria establecida por la normativa vigente.
                    </p>
                    <h3>
                        Datos recabados, finalidad y licitud
                    </h3>
                    <p>
                        Los datos de carácter personal tratados son los aportados por los usuarios a través de los formularios disponibles en este sitio web y son los mínimos exigibles para poder:
                        <ul>
                            <li>
                                Enviarle información sobre nuestros productos / servicios
                            </li>
                            <li>
                                Atender consultas o solicitudes
                            </li>
                            <li>
                                Llevar a cabo el servicio contratado.
                            </li>
                            <li>
                                Elaborar su perfil de usuario sobre la base de sus hábitos de navegación a fin de mantenerlo informado sobre los bienes y servicios que puedan resultar de su interés.
                            </li>
                        </ul>
                        Dichas finalidades están basadas en principios legales de tratamiento de los datos recogidos por la normativa vigente: para la ejecución de un contrato o la prestación de un servicio a los usuarios, para el cumplimiento de obligaciones legales, por el interés legítimo y con el consentimiento de los usuarios.
                    </p>
                    <h3>
                        Formularios web
                    </h3>
                    <p>Los datos personales recabados a través del formulario web de contacto se usan para poder atender cualquier consulta que el usuario realice a través del mismo.</p>
                    <p>Los datos personales recabados en el formulario de pedidos tienen la finalidad de tratar los datos necesarios para la correcta gestión de sus pedidos.</p>
                    <p>El tratamiento de los datos está legitimado por el consentimiento que usted nos presta al aceptar expresamente las condiciones del tratamiento informadas a través de esta política de privacidad.</p>
                    <h3>
                        Destinatarios de los datos
                    </h3>
                    <p>Los datos de carácter personal obtenidos a través de los formularios web son registrados y conservados en soportes electrónicos controlados y supervisados por el responsable del tratamiento.</p>
                    <p>Para los casos en los que la comunicación de datos a terceros no esté amparada en las bases legales establecidas en el apartado anterior, la comunicación de datos a otros destinatarios sólo se realizará si el usuario ha dado su consentimiento expreso.</p>
                    <p>Se mantienen unos criterios estrictos de selección de encargados de tratamiento y el compromiso contractual con cada uno de ellos para cumplir y hacer cumplir las obligaciones establecidas en materia de protección de datos.</p>
                    <p>En el caso de que existan transferencias internacionales de datos Conectia OÜ garantizará que la transferencia de sus datos personales se realiza de conformidad con las leyes de privacidad aplicables y, en particular, que se apliquen las medidas contractuales, técnicas y organizativas necesarias, como las claúsulas contractuales estándar aprobadas por la Comisión Europea.</p>
                    <h3>
                        Medidas técnicas y organizativas de protección de datos
                    </h3>
                    <p>Los soportes cuentan con las medidas técnicas y organizativas necesarias que garantizan la confidencialidad y la conservación de los datos personales obtenidos a través de la web.</p>
                    <p>Los datos personales recabados desde la web son tratados mediante protocolo HTML con certificado SSL válido.</p>
                    <p>El personal implicado en las operaciones de tratamiento de datos: acceso, edición, borrado, etc… está capacitado para ello, formado y comprometido con esta política de protección de datos.</p>
                    <h3>
                        Conservación de los datos
                    </h3>
                    <p>Los datos de carácter personal obtenidos a través del formulario de contacto serán conservados el tiempo necesario para atender la solicitud/consulta realizada.</p>
                    <p>Los datos de carácter personal obtenidos a través del formulario de pedidos serán conservados mientras exista una relación contractual y/o comercial con usted o mientras usted no ejerza su derecho de supresión, cancelación y/o limitación del tratamiento de sus datos.Vencida la relación contractual mantendremos la información debidamente bloqueada, sin darle ningún uso, mientras pueda ser necesaria para el ejercicio o defensa de reclamaciones o pueda derivarse algún tipo de responsabilidad judicial, legal o contractual de su tratamiento, que deba ser atendida y para lo cual sea necesaria su recuperación.</p>
                    <p>En caso que haya aceptado expresamente el envío de información comercial conservaremos sus datos de contacto hasta que usted nos comunique su decisión de anular dichos envíos comerciales.</p>
                    <p>Información sobre la existencia de decisiones automatizadas (incluida la elaboración de perfiles).Normalmente no adoptamos decisiones automáticas, pero cuanto lo hagamos dejaremos claro que se está adoptando ese tipo de decisión. No obstante, CONECTIA OÜ recoge varios apartados dedicados a la personalización de servicios a fin de mostrar publicidad personalizada al ingresar en su sitio Web y también para el envío de comunicaciones personalizadas.</p>
                    <p>CONECTIA OÜ reconoce la posibilidad de la realización de perfiles para mejorar su oferta de productos o personalizar el envío de ofertas comerciales o dar un trato más personalizado al cliente.</p>
                    <h3>
                        Oposición a que los datos sean tratados con fines publicitarios
                    </h3>
                    <p>
                        Si diste tu consentimiento para que utilizaran tus datos con fines publicitarios y no deseas seguir recibiendo publicidad, puedes revocar el consentimiento prestado en cualquier momento y a través del envío de un correo electrónico a mail del responsable <a href="mailto:info@conectia.pro">info@conectia.pro</a>.
                    </p>
                    <h3>
                        Cambios en la Política de Privacidad
                    </h3>
                    <p>
                        Es posible que modifiquemos la información contenida en esta Política de Privacidad y Cookies cuando lo estimemos conveniente. En caso de que lo hagamos, te lo notificaremos por distintas vías a través de la Plataforma (por ejemplo, a través de un banner, un pop-up o una notificación push), o incluso te lo comunicaremos a tu dirección de correo electrónico cuando el cambio en cuestión sea significativo para con tu privacidad, de manera que puedas revisar los cambios, valorarlos y, en su caso, oponerte o darte de baja en algún servicio o funcionalidad. En cualquier caso, te sugerimos que revises esta Política de Privacidad y Cookies de vez en cuando por si hubiera cambios menores o introducimos alguna mejora interactiva.
                    </p>
                </section>
                <section>
                    <h2>
                        AVISO LEGAL
                    </h2>
                    <h3>
                        Propiedad intelectual e industrial
                    </h3>
                    <p>
                        El diseño del portal y sus códigos fuente, así como los logos, marcas y demás signos distintivos que aparecen en el mismo pertenecen a (INVIERTIS PROPERTIES, S.L) y están protegidos por los correspondientes derechos de propiedad intelectual e industrial.
                    </p>
                    <h3>
                        Responsabilidad de los contenidos
                    </h3>
                    <p>
                        CONECTIA OÜ no se hace responsable de la legalidad de otros sitios web de terceros desde los que pueda accederse al portal. Tampoco responde por la legalidad de otros sitios web de terceros, que pudieran estar vinculados o enlazados desde este portal.
                    </p>
                    <p>
                        CONECTIA OÜ no será responsable del uso que terceros hagan de la información publicada en el portal, ni tampoco de los daños sufridos o pérdidas económicas que, deforma directa o indirecta, produzcan o puedan producir perjuicios económicos, materiales o sobre datos, provocados por el uso de dicha información.
                    </p>
                    <h3>
                        Reproducción de contenidos
                    </h3>
                    <p>
                        En virtud de lo dispuesto en la Ley de Propiedad Intelectual quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública de la totalidad o parte de los contenidos de esta página web, con fines comerciales en cualquier soporte, y por cualquier medio técnico, sin la autorización de CONECTIA OÜ
                    </p>
                    <h3>
                        Ley aplicable
                    </h3>
                    <p>CONECTIA OÜ se reserva la facultad de presentar las acciones civiles o penales que considere necesaria por la utilización indebida del sitio Web y contenidos, o por el incumplimiento de las presentes condiciones.</p>
                    <p>La relación entre el usuario y CONECTIA OÜ se regirá por la normativa vigente y de aplicación en el territorio nacional. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de estas condiciones, las partes someterán los conflictos a la jurisdicción ordinaria, sometiéndose a los Jueces y Tribunales que correspondan conforme a Derecho.</p>
                    <p>Así mismo le informamos que dispone de un procedimiento de resolución extrajudicial de controversias gratuito y accesible a todos los ciudadanos, se trata de la plataforma de resolución de litigios en línea de la Unión Europea, accesible a través del siguiente enlace <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show">https://ec.europa.eu/consumers/odr/main/?event=main.home2.show</a>.</p>
                    <h3>
                        Cookies
                    </h3>
                    <p>
                        En el caso de emplear tecnología “cookie” en la web, dispone de un apartado concreto que le informa sobre su existencia así como de su política de uso. Consulte el apartado “política de cookies”.
                    </p>
                    <p>
                        En caso contrario, no se emplean cookies de navegación.
                    </p>
                    <h3>
                        Datos personales de menores
                    </h3>
                    <p>
                        Esta página web no está dirigida a usuarios menores de edad. Si usted es menor de edad, por favor no intente registrarse como usuario. Si descubrimos que por error hemos obtenido información personal de un menor, eliminaremos dicha información lo antes posible.
                    </p>
                    <h3>
                        Ejercicio de los derechos de protección de datos
                    </h3>
                    <p>
                        De acuerdo con los derechos que confiere la normativa vigente en materia de protección de datos de carácter personal, el usuario podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratamiento de sus datos dirigiendo su petición a la dirección postal indicada o al correo electrónico <a href="mailto:info@conectia.pro">info@conectia.pro</a>.
                        <ul>
                            <li>
                                Para el ejercicio de los derechos deberá identificarse mediante la presentación de su DNI.
                            </li>
                            <li>
                                Para cualquier reclamación puede dirigirse al mismo correo indicado. Igualmente podrá dirigirse a la: <a href="www.aepd.es">Agencia Española de Protección de Datos</a>
                            </li>
                        </ul>
                    </p>
                </section>
            </article>
            <Footer></Footer>
        </div>
    )
}

export default PrivacyPolicy