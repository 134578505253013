import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"

import './hero-header.css'
import CustomButton from "../custom-button/custom-button.tsx";

function HeroHeader({ title, tagline, body, image, buttons, className="" }: HeroProps) {

    let showButtons = (buttons? : Array<ButtonProps>) => {
        if (buttons) {
          return buttons.map( (button,index) => {
            // return <CustomButton iconUrl={button.iconUrl} text={button.text} href={button.href} action={button.action} className={button.className} key={index}/>
            return <CustomButton {...button} key={button.id}/>
          });
        // <Button variant="primary" href={button.href} className={`call-to-action m-1 ${button.className}`} onClick={button.action}> {button.text??null}</Button>
        }
        return null;
    }
    
    return (
        <header className={`hero-header bg-primary text-light position-relative ${className}`}>
            <Container fluid="lg">
                <Row className="py-5">
                    <Col lg="6">
                        <h1 className="mb-4 title text-uppercase">{title}</h1>
                        {tagline ? <h2 className="mb-4 tagline">{tagline}</h2> : null}
                        <p className="mb-5">{body}</p>
                        {
                          showButtons(buttons)
                        }
                        {/* <Button variant="primary" href="#read-more" className="call-to-action">Leer Más</Button> */}
                    </Col>
                    <Col lg="6" className="d-none d-lg-block">
                        <Image src={image} fluid></Image>
                    </Col>
                </Row>
            </Container>
        </header >
    )
}

export default HeroHeader