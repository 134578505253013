import React from "react";
import './custom-button.css'
import { Button } from "react-bootstrap";

function CustomButton({ iconUrl, text, href, action, className, index = 1 }: ButtonProps) {
  return (
      <Button variant="primary" href={href} className={`call-to-action ${className??''} m-1`} onClick={action} key={index}>
        {text}
        {iconUrl ? <img src={iconUrl} alt="Icon" className="ms-2" /> : null}
      </Button>
  )
}

export default CustomButton