import React from "react"
import { Container } from "react-bootstrap"

import NavBar from "../navbar/navbar.tsx"
import Footer from "../footer/footer.tsx"

import ProfilesCarrousel from "../profiles/profiles-carrousel.tsx"
import Laptop from "../laptop/laptop.tsx"

import '../../assets/themes/conectia-dark.css'
import '../../assets/paint-patch/paint-patch.css'

import '../../assets/floaters/floaters.css'

/* ▼ WIP: Profile Card ▼ */
import MarcFore from "../../assets/profiles/Marc-Foreground.png"
import MarcBack from "../../assets/profiles/Marc-Background.png"
import AaronFore from "../../assets/profiles/Aaron-Foreground.png"
import AaronBack from "../../assets/profiles/Aaron-Background.png"
import JorgeFore from "../../assets/profiles/Jorge-Foreground.png"
import JorgeBack from "../../assets/profiles/Jorge-Background.png"
import FernandoFore from "../../assets/profiles/Fernando-Foreground.png"
import FernandoBack from "../../assets/profiles/Fernando-Background.png"
import Cosa1Fore from "../../assets/profiles/Thing1-Foreground.png"
import Cosa2Fore from "../../assets/profiles/Thing2-Foreground.png"
import CosasBack from "../../assets/profiles/Things-Background.png"
/* ▲ WIP: Profile Card ▲ */

let profiles = [
    {
        name: "Marc Molas Carol",
        jobtitle: "Awesome CTO",
        skills: ["AWS", "GCS", "Air Guitar"],
        pictures: {
            foreground: MarcFore,
            background: MarcBack,
        }
    },
    {
        name: "Aaron Hidalgo",
        jobtitle: "Developercito",
        skills: ["NodeJS", "ReactJS", "Javascript"],
        pictures: {
            foreground: AaronFore,
            background: AaronBack,
        }
    },
    {
        name: "Jorge Alexander Copara",
        jobtitle: "Desarrolador de Software",
        skills: ["Full Stack", "Frontend", "Backend"],
        pictures: {
            foreground: JorgeFore,
            background: JorgeBack,
        }
    },
    {
        name: "Fernando Yamir Vilca López",
        jobtitle: "Software engineer 🤖",
        skills: ["Javascript", "Angular", "Astro"],
        pictures: {
            foreground: FernandoFore,
            background: FernandoBack,
        }
    },
    {
        name: "Cosa 1",
        jobtitle: "Amigo del Gato en el Sombrero",
        skills: ["Rimas", "Alborotos", "y demás"],
        pictures: {
            foreground: Cosa1Fore,
            background: CosasBack,
        }
    },
    {
        name: "Cosa 2",
        jobtitle: "Amigo del Gato en el Sombrero",
        skills: ["Rimas", "Alborotos", "y demás"],
        pictures: {
            foreground: Cosa2Fore,
            background: CosasBack,
        }
    }
]

function Experiments() {
    return (
        <div className="conectia-dark bg-dark text-light">
            <NavBar className="bg-dark"></NavBar>
            <div className="m-auto">
                <Container fluid="lg">
                    <div className="m-5">
                        <ProfilesCarrousel
                            profiles={profiles}
                            className="bg-light text-dark"
                        ></ProfilesCarrousel>
                    </div>
                    <div className="row justify-content-around m-5">
                        <div className="paint-patch col-lg-6" style={{ "--paint-color": "var(--conectia-purple-filter)" } as React.CSSProperties}>
                            <h1> Something something, this is a title </h1>
                            <h2> Just to be sure it works</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris fermentum finibus ullamcorper. Mauris tincidunt ante id ipsum rutrum maximus. Etiam tempus pulvinar sapien non fringilla. Integer bibendum eget ligula ut finibus. Aenean eget sapien scelerisque, efficitur justo ac, scelerisque velit. Integer et laoreet tortor, sit amet congue nisi. Etiam cursus euismod tellus, nec sodales tortor lacinia ut. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In scelerisque lacus non elit lacinia sodales. Suspendisse iaculis varius facilisis. Integer quis fermentum arcu. Integer et rhoncus mi. Mauris vel arcu vitae orci pellentesque ultricies. Integer pulvinar aliquam sapien, ac malesuada arcu.
                            </p>
                        </div>
                        <div className="paint-patch col-6 col-lg-3 d-flex align-items-center" style={{ "--paint-color": "var(--conectia-green-filter)" } as React.CSSProperties}>
                            <Laptop></Laptop>
                        </div>
                    </div>
                </Container>

            </div>
            <Footer
                className="text-light"
            ></Footer>
        </div>
    )
}

export default Experiments