import React from "react"

import './laptop.css'

function Laptop(){
    return(
        <div className="laptop-container w-100">
        <div className="laptop-3D" style={{"--laptop-corner":"8px"} as React.CSSProperties}>
          <div className="laptop-screen">
            <div className="laptop-notch"></div>
            <div className="laptop-display"></div>
            <div className="laptop-lid"></div>
          </div>
          <div className="laptop-keyboard">
            <div className="laptop-keys"></div>
            <div className="laptop-trackpad"></div>
            <div className="laptop-lid"></div>
          </div>
        </div>
      </div>
    )
}

export default Laptop