import React from "react";
import { Row, Col } from "react-bootstrap";

import {useInView} from "react-intersection-observer";

import './connector.css'


function Connector({ index }) {

    const {ref, inView} = useInView()

    return (
        <Row ref={ref} className={`connector ${inView && ((index % 2 !== 0) ? 'left-to-right' : 'right-to-left')} d-none d-lg-flex justify-content-center py-3`}>
            <Col lg="2" className={`left-${(index % 2 !== 0) ? 'bottom' : 'top'}`}> </Col>
            <Col lg="4" className="center"></Col>
            <Col lg="2" className={`right-${(index % 2 === 0) ? 'bottom' : 'top'}`}></Col>
        </Row>
    )
}
export default Connector