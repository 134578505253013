import React from "react";

import './features-list.css'

function FeaturesList({ features, title, subtitles, numbered = true}: FeaturesProps) {

    const getDescriptions = (descriptions,index) => {        
        return descriptions.map((description, descriptionIndex) => {
            return (<p className="mb-3" key={`feautes-list-${index}-description-${descriptionIndex}`}>{description}</p>)
        })
    }

    return (
        <section className="features-list container-lg py-5">
            <h2 className={`text-center title`}>{title}</h2>
            {
                subtitles?.map((subtitle,index) => {
                    return (
                        <p className="text-center" key={`subtitle-${index}`}>{subtitle}</p>
                    )
                })
            }
            {
                features.map((feature, index) => {
                    return (
                        <div className="row my-5 feature-list-container" key={`feautes-list-${feature.id}`}>
                            {((index % 2 === 0) || (index % 3 === 0)) && <img src={feature.image} className={`img-fluid d-none d-lg-block ${((index !== 0) && (index % 3 === 0)) ? 'col-12 my-5' : 'col-5'} m-auto`} alt="Feature Left"></img>}
                            <div className={`${((index !== 0) && (index % 3 === 0)) ? 'col-12' : 'col-lg-7'} align-self-center feature-text-container`}>
                                <h3 className="mb-4 title">
                                    {numbered ? (index + 1) + '. ' + feature.title : feature.title}
                                </h3>
                                {feature.tagline ? <h4 className="mb-3 tagline">{feature.tagline}</h4> : null}
                                {
                                    typeof feature.description !== 'string'
                                        ? getDescriptions(feature.description,feature.id)
                                        : <p key={`feautes-list-${feature.id}-description-0`}>{feature.description}</p>
                                }
                            </div>
                            {((index % 2 !== 0) && (index % 3 !== 0)) && <img src={feature.image} className="img-fluid d-none d-lg-block col-5 m-auto" alt="Feature Right"></img>}
                        </div>
                    )
                })
            }
        </section >
    )

}

export default FeaturesList