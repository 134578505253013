import React from "react";
import { Badge, Image } from "react-bootstrap";

import './profile-card.css'

function ProfileCard({ name, jobtitle, skills, pictures, className }) {
    return (
        <div className="profile-card bg-transparent user-select-none">
            <div className="portrait position-relative">
                <Image fluid src={pictures.foreground} className="position-absolute bottom-0 foreground"></Image>
                <Image fluid src={pictures.background} className="rounded-top background"></Image>
            </div>
            <div className={`rounded-bottom p-3 ${className}`}> {/* REVIEW: So it may be themed from the caller component*/}
                <h1>
                    {name}
                </h1>
                <h2>
                    {jobtitle}
                </h2>
                <div className="d-flex gap-2">
                    {skills.map((skill,index) => {
                        return (
                            <Badge bg="secondary" key={`${name}-skill-${index}`}>{skill}</Badge>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default ProfileCard