import React from "react"

import NavBar from "../navbar/navbar.tsx"
import Footer from "../footer/footer.tsx"

import Advertisement from "../advertisement/advertisement.tsx"
import PoweredByConectia from "../powered-by-conectia/powered-by-conectia.tsx"
import Faq from "../faq/faq.tsx"
import AdvertisementTable from "../advertisement/advertisement-table.tsx"
import Seo from "../seo/seo.tsx"

import '../../assets/themes/conectia-light.css'

import featuresBannerImage1 from "../../assets/pricing/advertisement/Recurso 20.svg"
import featuresBannerImage2 from "../../assets/pricing/advertisement/Recurso 21.svg"
import featuresBannerImage3 from "../../assets/pricing/advertisement/Recurso 22.svg"

import ZapiensLogo from '../../assets/brands/zapiens.svg'
import PisosLogo from '../../assets/brands/pisos-en-el-barrio.svg'

let FeaturesBannerContent: Array<Feature> = [
    {
        id:1,
        image: featuresBannerImage1,
        title: "HORARiO FLEXiBLE",
        description: "Recupera el control y recorta costes fijos, desde 20 horas semanales."
    },
    {
        id:2,
        image: featuresBannerImage2,
        title: "COMPROMiSO FLEXiBLE",
        description: "Aumenta o reduce tu equipo en semanas, no en meses. Tu equipo, tu ritmo."
    },
    {
        id:3,
        image: featuresBannerImage3,
        title: "EXPERTOS AMiGABLES",
        description: "Obtén asistencia práctica de nuestro equipo dedicado con administración, recursos humanos y más."
    }
]

let questions = [
    { id: 1, question: "¿Cuánto cuesta contratar desarrolladores en Conectia?", answer: "Los precios oscilan entre 21€ y 52€ por hora. Sin costes encubiertos. Sin costes de inicio. El precio exacto dentro del rango varía según las habilidades, la experiencia de su desarrollador y la duración del compromiso laboral (mínimo 20 horas por semana). Con Conectia, siempre sabrás exactamente cuánto estás pagando y por qué estás pagando." },
    { id: 2, question: "¿Qué sucede si no estoy satisfecho con mi desarrollador de Conectia después de una semana?", answer: "Si no estás contento después de tu primera semana, haremos todo lo posible por encontrarte un nuevo desarrollador de Conectia y te daremos un reembolso en forma de crédito en tu próxima factura. Por favor, ten en cuenta que solo puedes reclamar crédito por tu primera semana si continúas trabajando con Conectia." },
    { id: 3, question: "¿Cómo funciona el pago?", answer: "Conectia envía facturas cada 15 días. La primera al inicio del mes y la segunda a mediados del mes. Las facturas contabilizan las horas trabajadas en el periodo de 15 días anterior y se generan siguiendo los términos contratados. ¿Buscas un trato diferente? Háznoslo saber. Somos flexibles y estamos contentos de discutir tus necesidades específicas." },
    { id: 4, question: "¿Existen costos iniciales relacionados con el reclutamiento o el contrato?", answer: "No y no. A diferencia de casi todas las empresas de reclutamiento y muchos mercados de talento, no cobramos ninguna tarifa inicial para cubrir costos relacionados con contratos o reclutamiento, o cualquier otra cosa." },
    { id: 5, question: "¿Cuántas horas a la semana puedo contratar a desarrolladores de Conectia?", answer: "Ofrecemos tres paquetes: “Tiempo completo“ (40 horas por semana), “Media Jornada“ (20 horas por semana) y “Paga por uso“. Para atraer a los mejores desarrolladores a Conectia, el compromiso mínimo que ofrecemos es de 20 horas por mes. " }
]

let quotes = [
    // { id: 1, picture: CosasBack, quote: "Es básicamente un tercio de lo que te costaría una agencia normal", quotee: "Caterina Carol, LoveChild Studios", brand: ZapiensLogo },
    // { id: 2, picture: CosasBack, quote: "La flexibilidad y la seguridad que da que los perfiles estén preseleccionados.", quotee: "Maria José Barroso", brand: PisosLogo },
]

const seo = {
    title: "Conectia - Precios",
    description: "Descubre nuestros planes y precios para nuestros servicios de desarrollo de software. Encuentra la mejor opción para tu empresa y presupuesto."
}

function Pricing() {
    return (
        <div className="conectia-light bg-light">
          <Seo title={seo.title} description={seo.description} />
            <NavBar className="bg-light"></NavBar>
            <div className="m-auto">
                <Advertisement features={FeaturesBannerContent}></Advertisement>
                <PoweredByConectia brands={[{id: 1, brand: ZapiensLogo}, {id: 2, brand: PisosLogo}]} quotes={quotes}/>
                <AdvertisementTable features={FeaturesBannerContent}></AdvertisementTable>
                <Faq
                    questions={questions}/>
            </div>
            <Footer
                className="text-light"
            ></Footer>
        </div>
    )
}

export default Pricing