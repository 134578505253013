import React from "react"

import NavBar from "../navbar/navbar.tsx"
import Footer from "../footer/footer.tsx"
import { Button, Container } from "react-bootstrap"

function ErrorPage(){
    return (
        <div className="conectia-dark bg-dark text-light">
            <NavBar className="bg-dark"></NavBar>
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: "60vh"}}>
                <Container fluid="lg">
                    <h1> Oops~! Parece que te has perdido</h1>
                    <h2> Puedes volver a nuestra página principal desde aquí</h2>
                    <Button href="/"> Volver al Inicio</Button>
                </Container>
            </div>
            <Footer
                className="text-light"
            ></Footer>
        </div>
    )
}

export default ErrorPage;
