import React, { useEffect } from "react";

import './contact-us.css'

function ContactUs({ image, formInfo, fromOtherPage = true }: ContactUsProps) {

    useEffect(() => {
        let formContainer = document.getElementById("form-container");

        let formLoadScript = document.createElement("script")
        formLoadScript.type = "text/javascript"
        formLoadScript.src = "//js-eu1.hsforms.net/forms/embed/v2.js"

        formLoadScript.addEventListener('load', () => {
            // @TS-ignore
            window.hbspt.forms.create({
                region: formInfo.region,
                portalId: formInfo.portalId,
                formId: formInfo.formId,
                target: '#form-container'
            })
        })

        formContainer.appendChild(formLoadScript);

    }, [formInfo])

    return (
        <section className="contact-us" id="contact-us">
            <div className="container-lg py-5">
                <div className="row">
                    {
                        image &&
                        <div className="col-lg-6 d-none d-lg-block">
                            <img src={image} alt="Contact Us" className="img-fluid px-5"></img>
                        </div>
                    }

                    <div className={`col-lg-6 py-5 ${!image ? 'm-auto' : ''} `}  >
                        {
                            fromOtherPage ? 
                                <h2 className="text-uppercase">
                                    CONÉCTATE CON NOSOTROS
                                </h2> :
                                <h1 className="text-uppercase">
                                    CONÉCTATE CON NOSOTROS
                                </h1>

                        }
                        <p>
                            Estamos aquí para escucharte y responder a todas tus consultas. ¡Contáctanos y descubre cómo podemos colaborar juntos para lograr tus objetivos!
                        </p>
                        <div id="form-container">

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs