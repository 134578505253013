import React from "react";

import './hero-banner.css'

function HeroBanner({ title, tagline }: HeroProps) {
    return (
        <section className="hero-banner text-center bg-primary text-secondary">
            <div className="container-lg text-uppercase px-3 py-5">
                <h2 className="">{title}</h2>
                <p className="pt-4">{tagline}</p>
            </div>
        </section>
    )
}

export default HeroBanner