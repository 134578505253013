import React from "react"

import NavBar from "../navbar/navbar.tsx"
import Footer from "../footer/footer.tsx"
import HeroSection from "../hero/hero-section.tsx"
import FeaturesBanner from "../features/features-banner.tsx"
import FeaturesList from "../features/features-list.tsx"
import HeroBanner from "../hero/hero-banner.tsx"
import ContactUs from "../contact-us/contact-us.jsx"

import '../../assets/themes/conectia-dark.css'

import heroSectionImage from "../../assets/landing-page/hero-header.webp"

import featuresBannerImage1 from "../../assets/landing-page/features-banner/Contract.png"
import featuresBannerImage2 from "../../assets/landing-page/features-banner/Task-List.png"
import featuresBannerImage3 from "../../assets/landing-page/features-banner/Profile-Check.png"

import featuresListImage1 from "../../assets/landing-page/features-list/1.png"
import featuresListImage2 from "../../assets/landing-page/features-list/2.png"
import featuresListImage3 from "../../assets/landing-page/features-list/3.png"
import Seo from "../seo/seo.tsx"

function Developers() {

    let HeroSectionContent = {
        title: "Te damos la bienvenida al futuro del trabajo tech a través de Conectia.",
        tagline: "Nuestra plataforma ofrece vuestro talento a las mejores empresas para que os encuentren.",
        body: "Conectamos tu talento con las mejores oportunidades laborales. Destaca en nuestra plataforma y permite que las empresas de élite te descubran. Tu futuro profesional comienza aquí.",
        image: heroSectionImage
    }

    let featuresBannerContent = [
        {
            id: 1,
            image: featuresBannerImage1,
            title: "Ambiente de Trabajo Atractivo",
            description: "Sumérgete en un entorno laboral vibrante donde la creatividad y la colaboración se fusionan. En nuestra empresa, no solo construimos código, construimos relaciones sólidas y un ambiente donde cada desarrollador puede prosperar."
        },
        {
            id: 2,
            image: featuresBannerImage2,
            title: "Proyectos Desafiantes e Innovadores",
            description: "Da vida a tus habilidades en un mundo de proyectos emocionantes y vanguardistas. En nuestra compañía, cada línea de código que escribes contribuye a algo más grande. Únete a nosotros y desafía constantemente tus límites mientras trabajas en proyectos que marcan la diferencia."
        },
        {
            id: 3,
            image: featuresBannerImage3,
            title: "Cultura de Desarrollo Profesional",
            description: "No es solo un trabajo, es un viaje de crecimiento. En nuestra empresa, tu desarrollo profesional es una prioridad. Ofrecemos oportunidades de aprendizaje continuo, programas de mentoría y un camino claro para que alcances nuevas alturas en tu carrera como desarrollador."
        }
    ]

    let featuresListContent = [
        {
            id: 1,
            image: featuresListImage1,
            title: "¡Despega tu Carrera!",
            tagline: "Rellena el Formulario y Haz que te Descubran",
            description: "Sube a bordo completando nuestro formulario. Cuéntanos sobre tu experiencia y habilidades únicas. Estamos aquí para destacar lo mejor de ti y ponerlo en el radar de las mejores empresas."
        },
        {
            id: 2,
            image: featuresListImage2,
            title: "Validación de Habilidades Técnicas",
            tagline: "Aseguramos tu Éxito con Nuestro Sello de Aprobación",
            description: "Nuestro equipo de expertos revisará tus habilidades con atención al detalle. Obtén el reconocimiento que mereces y sé parte de un grupo selecto de talentos validados que se destacan en el mundo del desarrollo."
        },
        {
            id: 3,
            image: featuresListImage3,
            title: "¡Conquista Oportunidades en Empresas Europeas!",
            tagline: "Navega hacia tu Futuro Profesional de la Mano de Nuestra Asesoría",
            description: "Una vez validado, te acompañaremos en la travesía hacia tu próximo desafío laboral. Te conectaremos con oportunidades emocionantes en empresas líderes en España. Tu éxito está a solo un paso de distancia."
        },
    ]

    let HeroBannerContent: HeroProps = {
        title: "¡OPTIMIZA TU EQUIPO HOY!",
        tagline: "POTENCIA TU EQUIPO TÉCNICO CON EXPERTOS SELECCIONADOS MINUCIOSAMENTE."
    }

    let HubspotForm: HubspotFormInfo = {
        region: "eu1",
        portalId: "143664462",
        formId: "a0d985b8-804d-48f5-84af-2f869d894faf",
    }
    
    const seo = {
        title: "Conectia - Para desarrolladores",
        description: "Explora nuestros servicios diseñados para desarrolladores. Herramientas y recursos para potenciar tus habilidades y acelerar tu carrera."
    }
    return (
        <div className="conectia-dark bg-dark text-light">
            <Seo title={seo.title} description={seo.description} />
            <NavBar className="bg-dark"></NavBar>
            <div className="m-auto">
                <HeroSection
                    title={HeroSectionContent.title}
                    tagline={HeroSectionContent.tagline}
                    body={HeroSectionContent.body}
                    image={HeroSectionContent.image}
                    buttons={[
                        {
                            id: 1,
                            text: "Contacta con Nosotros",
                            href: "#contact-us"
                        }
                    ]}>
                </HeroSection>
                <FeaturesBanner
                    features={featuresBannerContent}
                    className="text-dark">
                </FeaturesBanner>
                <FeaturesList
                    title="¿Cómo Funciona?"
                    features={featuresListContent}>
                </FeaturesList>
                <HeroBanner
                    title={HeroBannerContent.title}
                    tagline={HeroBannerContent.tagline}>
                </HeroBanner>
                <ContactUs
                    image = {featuresListImage1}
                    formInfo = {HubspotForm}>
                </ContactUs>
            </div>
            <Footer
                className="text-light"
            ></Footer>
        </div>
    )
}

export default Developers