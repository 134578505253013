import React from "react";
import { Helmet } from 'react-helmet';

function Seo({ title, description, metas }: Readonly<SEOProps>) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  let defaultMetas: Array<SEOMeta> = [
    {
      key: "description",
      name: "description",
      content: description ?? "Conectamos tu talento con las mejores oportunidades laborales. Destaca en nuestra plataforma y permite que empresas de élite te descubran.",
    },
    {
      key: "og:title",
      property: "og:title",
      content: title ?? "Conectia Developers",
    },
    {
      key: "og:description",
      property: "og:description",
      content: description ?? "Conectamos tu talento con las mejores oportunidades laborales. Destaca en nuestra plataforma y permite que empresas de élite te descubran.",
    },
    {
      key: "og:url",
      property: "og:url",
      content: window.location.href,
    },
    {
      key: "og:image",
      property: "og:image",
      content: "https://conectia.pro/favicon.ico",
    },
    {
      key: "og:type",
      property: "og:type",
      content: "website",
    },
    // {
    //   key: "twitter:title",
    //   property: "twitter:title",
    //   content: title ?? "Conectia Developers",
    // },
    // {
    //   key: "twitter:description",
    //   property: "twitter:description",
    //   content: description ?? "Conectamos tu talento con las mejores oportunidades laborales. Destaca en nuestra plataforma y permite que empresas de élite te descubran.",
    // },
    // {
    //   key: "twitter:site",
    //   property: "twitter:site",
    //   content: '@conectia-pro',
    // },
    // {
    //   key: "twitter:image",
    //   property: "twitter:image",
    //   content: "https://conectia.pro/favicon.ico",
    // },
    // {
    //   key: "twitter:card",
    //   property: "twitter:card",
    //   content: "summary_large_image",
    // }
  ];

  return (
    // metas ?
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={window.location.href} />
        {
          defaultMetas?.map((meta) => (
            <meta {...meta} />
          ))
        }{
          metas?.map((meta) => (
            <meta {...meta} />
          ))
        }
      </Helmet>
      // : <Helmet>
      //   <meta property="og:url" content="https://proxify.io/" />
      // </Helmet>
  )
}

export default Seo;