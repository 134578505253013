import React from "react";

import './advertisement.css'
import CustomButton from "../custom-button/custom-button.tsx";

function Advertisement({features}) {
    return (
        <section className="advertisement bg-primary text-light text-center position-relative mb-5 floaters-full">
            <section className="container-lg container-title px-3 py-5">
                <h1>PRECIOS FLEXIBLES,<br />SIN LETRA PEQUEÑA</h1>
                <p className="subtitle">Términos simples, adaptados a su empresa.</p>
            </section>
            <div className="container-lg container-advertisement">
              <h2>21€ - 52€</h2>
              <p className="time">por desarrollador, por hora</p>
              <CustomButton text="EMPEZAR" className="hover-ligth start" href="/contactanos" id={1}>
              </CustomButton>
              <div className="container-qualification">
                <span className="excellent">EXCELLENT</span> 
                <span className="stars">★  ★  ★  ★  ★</span>
              </div>
              <p className="tarifas">Sin tarifas ocultas • Incluye soporte administrativo y de recursos humanos<br />• ¿No está satisfecho después de una semana? Sin cargo</p>
            </div>
            <div className="container-lg features-banner">
                <div className="row justify-content-around">
                    {
                        features.map((feature, index: number) => {
                            return (
                                <div className="col-lg col-md-4 feature-container" key={`feautes-banner-${feature.id}`}>
                                    <img src={feature.image} alt={`Feature ${1}`} className="img-fluid d-block m-auto"></img>
                                    <h2 className="mt-5 text-secondary">{feature.title}</h2>
                                    <p className="mt-2">{feature.description}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Advertisement