import React from 'react'
import { NavLink } from "react-router-dom"

import { Container, Navbar, NavbarToggle, Nav } from 'react-bootstrap'

import './navbar.css'
import logo from '../../assets/logo/conectia-color.webp'

function NavBar({ className = "" }) {
    return (
        <Navbar expand="lg" sticky="top" className={'py-3 shadow ' + className}>
            <Container className='text-uppercase'>
                <Navbar.Brand>
                    <NavLink to="/" className='navbar-brand'>
                        <img src={logo} alt="Conectia" width={'180px'}></img>
                    </NavLink>
                </Navbar.Brand>
                <NavbarToggle>
                    ☰
                </NavbarToggle>
                <Navbar.Collapse className='justify-content-end text-nowrap'>
                    <Nav>
                        <NavLink to={"/why-conectia"} className={isActive => `nav-link ${isActive.isActive ? 'active' : ''}`}>
                            Ventajas
                        </NavLink>
                        <NavLink to={"/pricing"} className={isActive => `nav-link ${isActive.isActive ? 'active' : ''}`}>
                            Precios
                        </NavLink>
                        <NavLink to={"/developers"} className={isActive => `nav-link ${isActive.isActive ? 'active' : ''}`}>
                            Para Desarrolladores
                        </NavLink>
                        <NavLink to={"/"} className={isActive => `nav-link ${isActive.isActive ? 'active' : ''}`}>
                            Para Empresas
                        </NavLink>
                        <NavLink to={"/contactanos"} className={isActive => `nav-link ${isActive.isActive ? 'active' : ''}`}>
                            Contáctanos
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar