import React from "react";

import './features-banner.css'

function FeaturesBanner({ features, className = "" }: FeaturesProps) {

    return (
        <section className={"features-banner bg-primary " + className}>
            <div className="container-lg">
                <div className="row justify-content-around">
                    {
                        features.map((feature, index) => {
                            return (
                                <div className="col-lg col-md-4 container" key={`feautes-banner-${feature.id}`}>
                                    <img src={feature.image} alt={`Feature ${index}`} className="img-fluid d-block m-auto" />
                                    {feature.title ? <h3 className="mt-5 text-secondary text-center">{feature.title}</h3> : null}
                                    <p className="mt-2 text-center">{feature.description}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )

}

export default FeaturesBanner