import React from "react"

import NavBar from "../navbar/navbar.tsx"
import Footer from "../footer/footer.tsx"
import ContactUs from "../contact-us/contact-us.jsx"
import Seo from "../seo/seo.tsx"

import '../../assets/themes/conectia-dark.css'

import featuresListImage3 from "../../assets/factory/features-list/3.webp"

let HubspotForm: HubspotFormInfo = {
    region: "eu1",
    portalId: "143664462",
    formId: "39bb35e0-2700-49a0-8507-c61bca2ddd50",
}

const seo = {
    title: "Conectia - Contáctanos",
    description: "¿Tienes preguntas o estás interesado en nuestros servicios? ¡Contáctanos hoy mismo! Estamos aquí para ayudarte con cualquier consulta o solicitud que tengas."
}
function ContactUsPage() {
    return (
        <div className="conectia-dark bg-dark text-light">
            <Seo title={seo.title} description={seo.description} />
            <NavBar className="bg-dark"></NavBar>
            <ContactUs
                image={featuresListImage3}
                formInfo={HubspotForm}
                fromOtherPage={false}
            ></ContactUs>
            <Footer></Footer>
        </div>
    )
}

export default ContactUsPage