import React from "react";
import './hero-section.css'
import CustomButton from "../custom-button/custom-button.tsx";

function HeroSection({ title, tagline, body, image, buttons }: HeroProps) {
    let showButtons = (buttons? : Array<ButtonProps>) => {
        if (buttons) {
          return buttons.map( button => CustomButton(button));
        // <Button variant="primary" href={button.href} className={`call-to-action m-1 ${button.className}`} onClick={button.action}> {button.text??null}</Button>
        }
        return null;
    }
    return (
        <section className="hero-section container-lg position-relative">
            <div className=" row px-3 py-5">
                <div className="col-lg-7">
                    <h1 className="mb-4 title">{title}</h1>
                    <h2 className="mb-4 tagline">{tagline}</h2>
                    <p className="mb-5">{body}</p>
                    {
                          showButtons(buttons)
                        }
                    {/* <Button variant="primary" href="#contact-us" className="call-to-action">Contacta con Nosotros</Button> */}
                    </div>
            </div>
            <div className="col-5 d-none d-lg-block hero-section-image-container">
                <img src={image} alt="Hero Section" className="img-fluid"></img>
            </div>
        </section>
    )
}

export default HeroSection