import React from "react";
import { Image, Row, Col, Container } from "react-bootstrap";

import './powered-by-conectia.css'
import Quote from "./quote/quote.tsx";

function PoweredByConectia({ brands, quotes = [] }:PoweredByProps) {
    return (
        <section className="powered-by-conectia text-center py-5">
            <Container fluid="lg">
                <h2 className="text-uppercase text-primary"> Powered By Conectia </h2>
                <p className="m-auto">Te unes al club de Conectia, startups por todo elmundo Hispanohablante ya confían en nosotros.</p>
                <Row className="brands gap-5">
                    {brands.map((brand, index) => {
                        return (
                            <Col key={`brand-${brand.id}`}><Image src={brand.brand}/></Col>
                        )
                    })}
                </Row>
                {
                    quotes.length !== 0 &&
                    <Row className="quotes justify-content-center gap-5 py-5" xs={1} sm={2} lg={3}>
                        {quotes.map((quote, index) => {
                            return (
                                <Col>
                                    <Quote
                                        {...quote}
                                        key={`quote-${quote.id}`}
                                    ></Quote>
                                </Col>
                            )
                        })}
                    </Row>
                }
            </Container>
        </section>
    )
}

export default PoweredByConectia