import React from "react";
import ProfileCard from "./profile-card/profile-card.tsx";

import './profiles-carrousel.css'

function ProfilesCarrousel({ profiles, className }) {

    function getProfiles(profiles, className) {
        return profiles.map((profile, index) => {
            return (
                <ProfileCard
                    name={profile.name}
                    jobtitle={profile.jobtitle}
                    skills={profile.skills}
                    pictures={profile.pictures}
                    className={className}
                    key={`profiles-carrousel-${index}`}
                >
                </ProfileCard>
            )
        })
    }

    let profileCards = getProfiles(profiles, className);

    let styles = {
        "--profiles": profileCards.length
    } as React.CSSProperties

    return (
        <div className="profiles-carrousel-container">
            <div className="profiles-carrousel" style={styles}>
                {profileCards}
                {profileCards}
            </div>
        </div>

    )

}

export default ProfilesCarrousel
