import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"

import './hero-bottom.css'
import '../../assets/paint-mask/paint-mask.css'
import CustomButton from "../custom-button/custom-button.tsx"

function HeroBottom({ title, tagline, body, image, buttons }: HeroProps) {

  let showButtons = (buttons? : Array<ButtonProps>) => {
    if (buttons) {
      return buttons.map( (button, index) => {
        // return <CustomButton iconUrl={button.iconUrl} text={button.text} href={button.href} action={button.action} className={button.className} key={button.id} id={button.id}/>
        return <CustomButton {...button} key={button.id} />
      });
    // <Button variant="primary" href={button.href} className={`call-to-action m-1 ${button.className}`} onClick={button.action}> {button.text??null}</Button>
    }
    return null;
}

  return (
    <header className="hero-bottom text-light position-relative pb-5">
      <Container fluid="lg" className="paint-mask">
        <Row className="py-5">
          <Col lg="6">
            <h2 className="mb-4 title text-uppercase">{title}</h2>
            {tagline ? <h3 className="mb-4 tagline">{tagline}</h3> : null}
            <p className="mb-5">{body}</p>
            {
              showButtons(buttons)
            }
            {/* <Button variant="primary" href="#read-more" className="call-to-action">Leer Más</Button> */}
          </Col>
          <Col lg="6" className="d-none d-lg-block">
            <Image src={image} fluid></Image>
          </Col>
        </Row>
      </Container>
    </header >
  )
}

export default HeroBottom