import React from "react";
import { Link } from "react-router-dom";

import './footer.css'
import logo from '../../assets/logo/conectia-white.webp'

function Footer({ className = "" }) {

    let year = (new Date()).getFullYear().toString()

    return (
        <footer className={'bg-secondary ' + className}>
            <div className="container-lg px-3 py-5">
                <div className="row justify-content-between">
                    <Link to="/" className="col-lg-6 mb-5 mb-lg-0">
                        <img src={logo} alt="Conectia Logo" width={'250px'} className="img-fluid"></img>
                    </Link>
                    
                    <div className="info col-lg-6 row justify-content-lg-end">
                        <section className="col-4">
                            <h2 className="text-uppercase mb-3">Redes Sociales</h2>
                            <a href="https://www.linkedin.com/company/conectia-pro/" target="blank"> LinkedIn</a>
                        </section>
                        <section className="col-4">
                            <h2 className="text-uppercase mb-3">Legal</h2>
                            <Link to="/politicas-privacidad">Política de Privacidad</Link>
                        </section>
                    </div>
                </div>
                <hr className="my-4"></hr>
                <div> © {year} <Link to="/">Conectia™</Link> Todos los derechos reservados.</div>
            </div>
        </footer>
    )
}

export default Footer