import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Developers from './components/pages/developers.tsx';
import Factory from './components/pages/factory.tsx';
import ContactUsPage from './components/pages/contact-us.tsx';
import PrivacyPolicy from './components/pages/privacy-policy.tsx';
import WhyConectia from './components/pages/why-conectia.tsx';
import Pricing from './components/pages/pricing.tsx';
import Experiments from './components/pages/experiments.tsx';
import ErrorPage from './components/pages/error-page.tsx';

import './index.css'
import './assets/banner/banner.css'

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Factory />,
    errorElement: <ErrorPage/>
  },
  {
    path: "/developers",
    element: <Developers />
  },
  {
    path: "/contactanos",
    element: <ContactUsPage />
  },
  {
    path: "/politicas-privacidad",
    element: <PrivacyPolicy />
  },
  {
    path: "/why-conectia",
    element: <WhyConectia />
  },
  {
    path: "/pricing",
    element: <Pricing />
  },
  {
    path: "/experiments",
    element:<Experiments></Experiments>
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={Router}></RouterProvider>
  </React.StrictMode>
);
