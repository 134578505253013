import React, { useState } from "react"
import { Collapse, Container } from "react-bootstrap"

import './faq.css'

function Question({ question }) {
    const [open, setOpen] = useState(false);

    const openClose = () => setOpen(!open);

    return (
        <div className="question col-lg-6">
            <button className={`d-flex justify-content-between ${open ? 'text-primary' : ''}`} onClick={openClose}>{question.question} <span>{open ? '-' : '+'}</span></button>
            <Collapse in={open}>
                <div>
                    <p>{question.answer}</p>
                </div>
            </Collapse>
            <hr></hr>
        </div>
    )
}

function Faq({ questions }: FAQProps) {
    return (
        <section className="faq py-5">
            <Container fluid="lg">
                <h2 className="text-uppercase text-primary text-center mb-5">Preguntas Frequentes</h2>
                <div className="row g-5">
                    {
                        questions.map((question, index) => {
                            return (
                                <Question
                                    question={question}
                                    key={`faq-${question.id}`}
                                >
                                </Question>
                            )

                        })
                    }
                </div>
            </Container>
        </section>
    )
}

export default Faq