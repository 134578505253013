import React from "react";

import { Col, Container } from "react-bootstrap";

import '../custom-button/custom-button.css'
import './advertisement-table.css'

function AdvertisementTable({ features }) {
    return (
        <section className="advertisement-table-banner bg-primary text-light text-center position-relative mb-5 floaters-bottom">
            <Container fluid="lg py-5">
                <div className="px-3 py-5 floaters-around">
                    <h2 className="">CONECTIA VS CONTRATACIÓN TRADICIONAL</h2>
                    <p>Optimiza tus contrataciones y recibe más por menos.<br />Recupera el control de tu presupuesto</p>
                </div>

                <Col lg={9} className="py-5 m-auto">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th><div className="box featured text-uppercase">Conectia</div></th>
                                <th><div className="box">Agencia <br /> Tradicional</div></th>
                                <th><div className="box">Empleado <br /> Tradicional</div></th>
                            </tr>
                        </thead>
                        <tbody className="text-dark">
                            <tr>
                                <td>Coste por hora</td>
                                <td>21€ — 52€</td>
                                <td>70€ — 180€</td>
                                <td>Mayor</td>
                            </tr>
                            <tr>
                                <td>Costes de reclutamiento</td>
                                <td>0€</td>
                                <td>0€</td>
                                <td>10,000€ — 20,000€</td>
                            </tr>
                            <tr>
                                <td>Tiempo de contratación</td>
                                <td>72h</td>
                                <td>1 — 2 meses</td>
                                <td>3 — 4 meses</td>
                            </tr>
                            <tr>
                                <td>Calidad asegurada</td>
                                <td>Siempre </td>
                                <td>A veces </td>
                                <td>Nunca </td>
                            </tr>
                            <tr>
                                <td>Costes de terminación</td>
                                <td>Inexistentes</td>
                                <td>Altos</td>
                                <td>Muy altos</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td><a className="bg-secondary text-primary" href="/contactanos">EMPIEZA</a></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </Col>
            </Container>
        </section>
    )
}

export default AdvertisementTable