import React from "react"

import NavBar from "../navbar/navbar.tsx"
import Footer from "../footer/footer.tsx"
import HeroSection from "../hero/hero-section.tsx"
import FeaturesBanner from "../features/features-banner.tsx"
import FeaturesList from "../features/features-list.tsx"
import HeroBanner from "../hero/hero-banner.tsx"
import ContactUs from "../contact-us/contact-us.jsx"

import '../../assets/themes/conectia-light.css'

import heroSectionImage from "../../assets/factory/hero-header.webp"

import featuresBannerImage1 from "../../assets/factory/features-banner/Professional-Values.svg"
import featuresBannerImage2 from "../../assets/factory/features-banner/Looking-Glass.svg"
import featuresBannerImage3 from "../../assets/factory/features-banner/CV-Evaluation.svg"

import featuresListImage1 from "../../assets/factory/features-list/1.webp"
import featuresListImage2 from "../../assets/factory/features-list/2.webp"
import featuresListImage3 from "../../assets/factory/features-list/3.webp"
import featuresListImage4 from "../../assets/factory/features-list/4.webp"
import Seo from "../seo/seo.tsx"


let HeroSectionContent = {
    title: "Selecciona, contrata y paga a tu equipo tech especializado a través de Conectia.",
    tagline: "Contrata talento tecnológico sin complicaciones, tu equipo en 72h",
    body: "Olvídate de los procesos de selección lentos, de la nómina, las cargas administrativas o costes de contratación. Con Conectia, tú eliges cómo es tu equipo ideal y nosotros nos ocupamos del resto.",
    image: heroSectionImage
}

let FeaturesBannerContent: Array<Feature> = [
    {
        id: 1,
        image: featuresBannerImage1,
        title: "Habilidades técnicas comprobadas",
        description: "En nuestro proceso, evaluamos la competencia y especialización de cada candidato a través de rigurosos ejercicios y exámenes, asegurando una selección precisa y equipos altamente capacitados."
    },
    {
        id: 2,
        image: featuresBannerImage2,
        title: "Soporte experto",
        description: "Nuestros especialistas en reclutamiento y formación de equipos técnicos se dedican a asistirte, diseñando tu equipo y seleccionando el talento perfecto para potenciar tu empresa."
    },
    {
        id: 3,
        image: featuresBannerImage3,
        title: "Amplia selección de candidatos",
        description: "Nuestra politica de selección de candidatos nos asegura que disponemos de +100 talentos para todos los niveles de seniority que necesites."
    }
]

let FeaturesListContent: Array<Feature> = [
    {
        id: 1,
        image: featuresListImage1,
        title: "Agenda una reunión con nuestro equipo de expertos",
        tagline: "Elige el día y horario que te quede cómodo para reunirte con nuestros especialistas.",
        description: "Selecciona a tu conveniencia el día y horario para reunirte con nuestros expertos en reclutamiento tech. Estamos aquí para adaptarnos a tu agenda y ofrecerte asesoramiento especializado en la búsqueda y selección de talento tecnológico. Ya sea para cubrir roles en desarrollo de software, ingeniería o áreas afines, nuestro equipo dedicado está listo para ayudarte a encontrar profesionales altamente calificados."
    },
    {
        id: 2,
        image: featuresListImage2,
        title: "Diseña tu equipo junto a nosotros",
        tagline: "Un experto en reclutamiento tech se reunirá contigo entender tus necesidades y buscar al equipo perfecto para tu empresa.",
        description: "Concertaremos una reunión personalizada entre tú y nuestro experto en reclutamiento tech. Analizaremos detalladamente tus necesidades para encontrar el equipo perfecto que impulse el éxito de tu empresa. Nuestro enfoque centrado en el cliente garantiza una colaboración efectiva y resultados excepcionales en la selección de talento tecnológico. Confía en nosotros para construir un equipo altamente competente y alineado con tus objetivos empresariales. ¡Estamos comprometidos a llevar tu empresa al siguiente nivel!"
    },
    {
        id: 3,
        image: featuresListImage3,
        title: "Recibe la propuesta",
        tagline: "En menos de 72h recibirás la propuesta con el equipo creado. Danos feedback y valida para avanzar la contratación.",
        description: "En menos de 72 horas, recibirás nuestra propuesta detallada con el equipo técnico que hemos creado para tu empresa. Valoramos tu feedback y validación para garantizar que el proceso de contratación avance de manera ágil y eficiente. Nos comprometemos a brindarte un equipo altamente calificado y adaptado a tus necesidades. Tu participación activa es clave para asegurar que el proceso sea transparente y satisfactorio. Estamos aquí para facilitar el crecimiento de tu empresa a través de la incorporación de talento excepcional. ¡Confía en nosotros para llevar tu equipo técnico al siguiente nivel!"
    },
    {
        id: 4,
        image: featuresListImage4,
        title: "Comienza a trabajar con tu nuevo equipo",
        tagline: "Nos ocuparemos de la contratación, formalización y pago de tu nuevo equipo para que empiece a trabajar lo antes posible.",
        description: "Nos encargaremos integralmente de la contratación, formalización y pago de tu nuevo equipo, asegurando un inicio rápido y sin complicaciones. Nuestra eficiente gestión facilita el proceso, permitiéndote centrarte en el rendimiento y éxito del equipo desde el primer día. Con nosotros, la incorporación de talento es fluida y libre de preocupaciones administrativas. ¡Confía en nuestra experiencia para impulsar el inicio exitoso de tu equipo!"
    }
]

let HeroBannerContent: HeroProps = {
    title: "¡OPTIMIZA TU EQUIPO HOY!",
    tagline: "POTENCIA TU EQUIPO TÉCNICO CON EXPERTOS SELECCIONADOS MINUCIOSAMENTE."
}

let HubspotForm: HubspotFormInfo = {
    region: "eu1",
    portalId: "143664462",
    formId: "5e251979-97bc-41b1-941c-4ef69f57933f",
}

const seo = {
    title: "Conectia - Para empresas",
    description: "Nuestros servicios de desarrollo de software incluyen web, móvil, consultoría y más. Descubre cómo podemos hacer crecer tu negocio."
}

function Factory() {
    return (
        <div className="conectia-light bg-light">
            <Seo title={seo.title} description={seo.description} />
            <NavBar className="bg-light"></NavBar>
            <div className="m-auto">
                <HeroSection
                    title={HeroSectionContent.title}
                    tagline={HeroSectionContent.tagline}
                    body={HeroSectionContent.body}
                    image={HeroSectionContent.image}
                    buttons={[
                        {
                            id: 1,
                            text: "Contacta con Nosotros",
                            href: "#contact-us"
                        }
                    ]}>
                </HeroSection>
                <FeaturesBanner
                    features={FeaturesBannerContent}
                    className="text-light">
                </FeaturesBanner>
                <FeaturesList
                    title="¿Cómo Funciona?"
                    features={FeaturesListContent}>
                </FeaturesList>
                <HeroBanner
                    title={HeroBannerContent.title}
                    tagline={HeroBannerContent.tagline}>
                </HeroBanner>
                <ContactUs
                    image={featuresListImage3}
                    formInfo={HubspotForm}>
                </ContactUs>
            </div>
            <Footer
                className="text-light"
            ></Footer>
        </div>
    )
}

export default Factory