import React from "react";
import { Row, Col, Image } from "react-bootstrap";

import './roadmap.css'
import Connector from "./connector/conector";

function RoadMap({ title, steps } : Readonly<RoadMapProps>) {

    const getDescriptions = (descriptions, index) => {
        return descriptions.map((description, descriptionIndex) => {
            return (<p className="mb-3" key={`roadmap-${index}-description-${descriptionIndex}`}>{description}</p>)
        })
    }

    return (
        <section className="roadmap container-lg py-5">
            <h2 className="text-center text-uppercase title" key={ Math.floor(Math.random() * 100)}>
                {title}
            </h2>
            <div className="info-container">
                {
                    steps.map((step, index) => {
                        return (
                            <>
                                {index !== 0 && <Connector index={index} key={`connector-${step.id - 1}`} />}

                                <Row className="justify-content-between step" key={`roadmap-${step.id}`}>
                                    {(index % 2 !== 0) && <Image fluid className="col-lg-4 d-none d-lg-block" src={step.image} alt="Step Left"></Image>}
                                    <Col>
                                        <h3 className="text-uppercase title mb-4">{step.title}</h3>
                                        {
                                            typeof step.description !== 'string'
                                                ? getDescriptions(step.description, step.id)
                                                : <p key={`roadmap-${step.id}-description-0`}>{step.description}</p>
                                        }
                                    </Col>
                                    {(index % 2 === 0) && <Image fluid className="col-lg-4 d-none d-lg-block" src={step.image} alt="Step Right"></Image>}
                                </Row>
                            </>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default RoadMap