import React from "react";
import { Image } from "react-bootstrap";

import './quote.css'

function Quote({ picture, quote, quotee, brand }:Quote) {
    return (
        <div className="quote d-flex flex-column m-auto justify-content-around align-items-center p-3 p-md-2 p-lg-5 ">
                <Image src={picture} className="portrait w-50" roundedCircle />
                <div className="text-center">
                    <p>"{quote}"</p>
                    <p className="text-primary quotee">{quotee}</p>
                </div>
                <Image src={brand} className="brand"></Image>
                <div className="decorator"></div>
        </div>
    )
}

export default Quote;