import React from "react"

import NavBar from "../navbar/navbar.tsx"
import Footer from "../footer/footer.tsx"

import HeroHeader from "../hero/hero-header.tsx"
import FeaturesBanner from "../features/features-banner.tsx"
import FeaturesList from "../features/features-list.tsx"
import RoadMap from "../roadmap/roadmap.tsx"

import '../../assets/themes/conectia-light.css'

import heroSectionImage from "../../assets/why-conectia/hero-header.webp"
import heroBottomSectionImage from "../../assets/why-conectia/hero-bottom.webp"

import featuresBannerImage1 from "../../assets/why-conectia/feature-banner/Recurso 9.webp"
import featuresBannerImage2 from "../../assets/why-conectia/feature-banner/Recurso 10.webp"
import featuresBannerImage3 from "../../assets/why-conectia/feature-banner/Recurso 11.webp"
import featuresBannerImage4 from "../../assets/why-conectia/feature-banner/Recurso 12.webp"
import featuresBannerImage5 from "../../assets/why-conectia/feature-banner/Recurso 13.webp"

import featuresListImage1 from "../../assets/why-conectia/feature-list/Recurso 7.webp"
import featuresListImage2 from "../../assets/why-conectia/feature-list/Recurso 8.webp"
import HeroBottom from "../hero/hero-bottom.tsx"

import roadmapImage1 from "../../assets/why-conectia/roadmap/Recurso 14.webp"
import roadmapImage2 from "../../assets/why-conectia/roadmap/Recurso 15.webp"
import roadmapImage3 from "../../assets/why-conectia/roadmap/Recurso 16.webp"
import roadmapImage4 from "../../assets/why-conectia/roadmap/Recurso 17.webp"
import Seo from "../seo/seo.tsx"

let HeroSectionContent = {
    title: "Selecciona, contrata y paga a tu equipo tech especializado a través de Conectia.",
    tagline: "Contrata talento tecnológico sin complicaciones, tu equipo en 72h",
    body: "Olvídate de los procesos de selección lentos, de la nómina, las cargas administrativas o costes de contratación. Con Conectia, tú eliges cómo es tu equipo ideal y nosotros nos ocupamos del resto.",
    image: heroSectionImage
}

let FeaturesBannerContent: Array<Feature> = [
    {
        id: 1,
        image: featuresBannerImage1,
        description: "Creemos que encontrar el desarrollador adecuado es un proceso delicado y complejo."
    },
    {
        id: 2,
        image: featuresBannerImage2,
        description: "Creemos en la flexibilidad para ampliar y reducir su equipo. Cuando quieres."
    },
    {
        id: 3,
        image: featuresBannerImage3,
        description: "Nosotros creemos en el factor humano."
    },
    {
        id: 4,
        image: featuresBannerImage4,
        description: "Creemos en una combinación perfecta para cada compromiso."
    },
    {
        id: 5,
        image: featuresBannerImage5,
        description: "Creemos en una combinación perfecta para cada compromiso."
    }
]

let FeaturesListContent: Array<Feature> = [
    {
        id: 1,
        image: featuresListImage1,
        title: "CONTRATACIÓN REINVENTADA.",
        description: [
            "Los tiempos han cambiado. Los grandes desarrolladores son más difíciles de encontrar. Y prefieren trabajar de forma remota. ¿El resultado? Los costes y los tiempos de contratación están aumentando. Rápido. Desafortunadamente, esto es decisivo para muchas empresas.",
            "Estamos aquí para mostrarle una manera más fácil. Todo lo que tiene que hacer es confiar en que lo cumpliremos. Y lo haremos.",
            "Porque el principal desafío para el crecimiento en 2024 no es el conocimiento técnico.",
            "Es encontrar el talento adecuado. Rápido."
        ]
    },
    {
        id: 2,
        image: featuresListImage2,
        title: "COMPROMETIDOS CON TU FUTURO.",
        description: [
            "Realmente nos preocupamos por crear un crecimiento radical para nuestros clientes. Sin embargo, frente a una economía global en desaceleración, una inflación creciente y una incertidumbre geopolítica, el crecimiento que genere ganancias y valor para los accionistas es más difícil de alcanzar que nunca.",
            "Somos dolorosamente conscientes de que el gran talento es un factor fundamental para lograrlo. Sin embargo, muchas empresas temen contratar desarrolladores remotos.",
            "¿Por qué? Nos hacemos esa pregunta todos los días. Principalmente es falta de conciencia. Sabemos que encontrar el mejor talento rápidamente es una ventaja recompensada para aquellos lo suficientemente valientes como para probar algo nuevo."
        ]
    }
]

let RoadMapContent = [
    {
        id: 1,
        title: "SIMPLICIDAD",
        image: roadmapImage1,
        description: [
            "Hacemos que sea más sencillo y fácil para usted contratar expertos especializados, ampliar su equipo y reducir costos.",
            "Disfrute de gastos generales reducidos, plazos más cortos, menos administración, pagos sin fricciones, incorporación rápida y no más problemas de cumplimiento."
        ]
    },
    {
        id: 2,
        title: "TALENTO EXCEPCIONAL",
        image: roadmapImage2,
        description: [
            "Nos especializamos en seleccionar y nutrir al mejor talento tecnológico del mundo. Cada uno de nuestros más de 3750 miembros ha pasado un riguroso proceso de investigación, lo que garantiza una red exclusiva donde solo se acepta el 2% de los solicitantes.",
            "Una vez aceptados, nutrimos su crecimiento y celebramos sus logros.",
            "A través de capacitación continua, apoyo profesional y confianza genuina, nuestro objetivo es crear relaciones profesionales duraderas y de largo plazo."
        ]
    },
    {
        id: 3,
        title: "HABLAMOS TU IDIOMA",
        image: roadmapImage3,
        description: [
            "¿Experiencias negativas con reclutadores técnicos antes? ¿No sabían la diferencia entre Node.js y Django? Sí, nosotros también hemos estado allí. Somos muy conscientes de los puntos débiles de la contratación de tecnología y es por eso que somos tan buenos para comprender sus necesidades específicas.",
            "Al unirse a Conectia, obtiene acceso a un entorno de profesionales que ofrecen soporte técnico dedicado, asesoramiento confiable y presentaciones personalizadas, en su idioma.",
        ]
    },
    {
        id: 4,
        title: "CONTIGO EN CADA PASO",
        image: roadmapImage4,
        description: [
            "¿Experiencias negativas con reclutadores técnicos antes? ¿No sabían la diferencia entre Node.js y Django? Sí, nosotros también hemos estado allí. Somos muy conscientes de los puntos débiles de la contratación de tecnología y es por eso que somos tan buenos para comprender sus necesidades específicas.",
            "Al unirse a Conectia, obtiene acceso a un entorno de profesionales que ofrecen soporte técnico dedicado, asesoramiento confiable y presentaciones personalizadas, en su idioma.",
        ]
    }
]

let HeroBottomSectionContent = {
    title: "¿Listo para explorar el futuro de la contratación remota? Tu viaje comienza aquí",
    tagline: "Contrata talento tecnológico sin complicaciones, tu equipo en 72h",
    body: `El primer paso para descubrir el talento oculto del mundo comienza con una conversación entre usted y un experto en contratación de Conectia. Descubra una red global de talentos adaptada a usted y con el mínimo esfuerzo requerido.

    ¿a que estas esperando?`,
    image: heroBottomSectionImage
}

const clicked = () => {
    console.log("clicked");
}

const seo = {
    title: "Conectia - Porqué Conectia?",
    description: "Descubre por qué somos tu mejor opción para desarrollo de software. Conoce nuestro equipo, experiencia y valores y lleva tu proyecto al siguiente nivel."
}

function WhyConectia() {
    return (
        <div className="conectia-light bg-light">
            <Seo title={seo.title} description={seo.description} />
            <NavBar className="bg-light"></NavBar>
            <div className="m-auto">
                <HeroHeader
                    title={HeroSectionContent.title}
                    tagline={HeroSectionContent.tagline}
                    body={HeroSectionContent.body}
                    image={HeroSectionContent.image}
                    buttons={[
                        {
                            id: 1,
                            text: "Leer más",
                            href: "/",
                            className: "hover-ligth"
                        }
                    ]}
                    className="floaters-half"
                    >
                </HeroHeader>
                <FeaturesList
                    title="LA VÍA CONECTIA"
                    subtitles={[
                        "Se pueden encontrar desarrolladores excepcionalmente talentosos en cualquier lugar y en todas partes. Sin embargo, muchas empresas contratan a la antigua usanza, eligiendo la ubicación (y la tradición) antes que el talento.",
                        "¿Por qué? Porque no saben que el futuro de la contratación remota ya está aquí."
                    ]}
                    numbered={false}
                    features={FeaturesListContent}>
                </FeaturesList>
                <FeaturesBanner
                    features={FeaturesBannerContent}
                    className="text-light">
                </FeaturesBanner>
                <RoadMap
                    title="Porqué Conectia"
                    steps={RoadMapContent}></RoadMap>
                <HeroBottom
                    title={HeroBottomSectionContent.title}
                    // tagline={HeroBottomSectionContent.tagline}
                    body={HeroBottomSectionContent.body}
                    image={HeroBottomSectionContent.image}
                    buttons={[
                        {
                            id: 1,
                            text: "Leer más",
                            href: "/",
                            className: "hover-ligth"
                        },
                        {
                            id: 2,
                            text: "Contáctanos",
                            href: "/#contact-us",
                            action: clicked,
                            className: "hover-ligth outline"
                        }
                    ]}>
                </HeroBottom>
            </div>
            <Footer
                className="text-light"
            ></Footer>
        </div>
    )
}

export default WhyConectia